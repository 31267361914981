<template>
  <v-card>
    <v-toolbar
      dark
      dense
      :color="estudio.instalacion?.estado_actual_int?.color"
    >
      <v-icon left>{{ estudio.instalacion?.estado_actual_int?.icon }}</v-icon>
      <v-toolbar-title
        >{{ estudio.idEstudio }} - {{ estudio.nombreProyecto }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn small icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="pa-4 d-flex flex-column" style="gap: 20px">
      <div class="d-flex justify-end mb-n8">
        <v-btn @click.stop="detailsId = estudio.idEstudio" small rounded>
          <v-icon left>mdi-arrow-top-right-thick</v-icon>
          Abrir detalles
        </v-btn>
      </div>

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-credit-card</v-icon>
        <h4>Pagos</h4>
        <div></div>
        <PagosInstalacion
          :key="estudio.idEstudio"
          :idEstudio="estudio.idEstudio"
          tipo="instalacion"
        />
      </div>

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-text-long</v-icon>
        <h4>Descripción</h4>
        <div></div>
        <div>
          <v-textarea
            v-model="estudio.descripcion"
            readonly
            outlined
            hide-details
            no-resize
            auto-grow
          ></v-textarea>
          <div
            v-if="estudio.descripcion != estudio_freeze.descripcion"
            class="d-flex justify-end mt-2"
          >
            <v-btn small color="primary">
              <v-icon small left>mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </div>
        </div>
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-text-short</v-icon>
        <h4>Datos</h4>
        <div></div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              hide-details
              label="Direccion"
              readonly
              v-model="estudio.cups_principal.direccion"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              hide-details
              label="GPS"
              readonly
              :value="[estudio.latitud, estudio.longitud].join('   ')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Instaladora asignada"
              :value="estudio.instaladora?.nombreInstaladora"
              icon="mdi-wrench"
              prevent-default-editing
              :editing="cambios.instaladora"
              @editing="(v) => (cambios.instaladora = v)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Comercial asignado"
              :value="
                [estudio.comercial, estudio.subComercial]
                  .filter((x) => x)
                  .join(' - ')
              "
              icon="mdi-account"
              prevent-default-editing
              :editing="cambios.comercial"
              @editing="(v) => (cambios.comercial = v)"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <GestionTarjeta
              title="Responsable"
              :value="estudio.responsable?.nombre || 'Sin responsable asignado'"
              icon="mdi-account-tie"
              :editable="false"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <!-- <v-text-field
              outlined
              hide-details
              type="date"
              label="Fecha firma estudio"
              v-model="estudio.instalacion.fechaFirmaEstudio"
            ></v-text-field> -->
            <v-date-field
              label="Fecha firma estudio"
              v-model="estudio.instalacion.fechaFirmaEstudio"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-date-field
              label="Fecha firma contrato"
              v-model="estudio.instalacion.fechaFirmaContrato"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-date-field
              label="Fecha fin obra"
              v-model="estudio.instalacion.fechaFirmaObra"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Potencia instalación"
              suffix="kWp"
              v-model="estudio.instalacion.potenciaInstalacion"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="d-flex align-center" style="gap: 10px" >
            <v-combobox
              :items="estudio.autoestudios"
              item-text="precio_instalacion"
              item-value="precio_instalacion"
              outlined
              hide-details
              type="number"
              label="Precio venta"
              suffix="€"
              v-model="estudio.instalacion.precioVenta"
              @change="descontarIva"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.potencia_instalacion }} kWp</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    [
                      item.curva,
                      item.estructura,
                      item.aplicar_iva ? "IVA" : "SIN IVA",
                    ].join(" | ")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text
                  >{{ item.precio_instalacion }} €</v-list-item-action-text
                >
              </template>
            </v-combobox>

            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on : menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on : tooltip }">
                    <v-btn small fab v-on="{...menu, ...tooltip}" v-bind="attrs" :disabled="!estudio.instalacion.precioVenta" >
                      <v-icon v-if="!descuentoPrecioVenta" >mdi-label-percent</v-icon>
                      <span v-else> {{  descuentoPrecioVenta  }} % </span>
                    </v-btn>
                  </template>
                  Porcentaje de descuento
                </v-tooltip>
              </template>
              <v-card class="pa-2">
                <v-combobox
                  dense
                  :items="[5, 10, 20]"
                  outlined
                  hide-details
                  type="number"
                  suffix="%"
                  v-model="descuentoPrecioVenta"
                  @change="aplicarDescuento"
                >
                </v-combobox>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Precio compra"
              suffix="€"
              v-model="estudio.instalacion.precioCompra"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              label="Tipo de cliente"
              :items="tiposCliente"
              v-model="estudio.instalacion.idTipoCliente"
              outlined
              hide-details
              clearable
              item-text="nombre"
              item-value="idTipo"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-checkbox
              hide-details
              label="Financiado"
              v-model="estudio.instalacion.financiado"
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              no-resize
              rows="1"
              auto-grow
              hide-details
              label="Comentario"
              v-model="estudio.instalacion.comentario"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              no-resize
              rows="1"
              auto-grow
              hide-details
              label="Feed-back"
              v-model="estudio.instalacion.feedback"
            ></v-textarea>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Nº Paneles"
              v-model="estudio.instalacion.nPaneles"
              append-icon="mdi-solar-panel"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Potencia inversor"
              suffix="kW"
              v-model="estudio.instalacion.potenciaInversor"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              hide-details
              type="number"
              label="Superficie"
              suffix="m2"
              v-model="estudio.instalacion.superficie"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-switch
              class="mt-0"
              hide-details
              v-model="estudio.instalacion.esEmpresa"
              flat
              label="Es una empresa"
            ></v-switch>
          </v-col> -->
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              :loading="loading"
              small
              color="primary"
              @click.stop="updateInstalacion"
            >
              <v-icon left>mdi-content-save</v-icon>
              Guardar</v-btn
            >
          </v-col>
        </v-row>
      </div>

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-gavel</v-icon>
        <h4>Tramites</h4>
        <div></div>
        <TramitacionesInstalacion
          :idInstalacion="estudio.instalacion.idInstalacion"
          :tramites="estudio.instalacion.tramites"
        />
      </div>

      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-paperclip</v-icon>
        <h4>Adjuntos <span>interno</span></h4>
        <div></div>
        <DocumentacionEstudio :idEstudio="idEstudio" sub="adjuntos" />
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-paperclip</v-icon>
        <h4>Documentacion Final</h4>
        <div></div>
        <DocumentacionEstudio :idEstudio="idEstudio" sub="final" />
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
      >
        <v-icon large>mdi-calendar</v-icon>
        <h4>Calendario</h4>
        <div></div>
        <CalendarioEstudio :idEstudio="idEstudio" />
      </div>
      <div
        style="display: grid; grid-template-columns: 0fr 1fr; gap: 10px"
        class="align-center"
        v-if="estudio.cronograma"
      >
        <v-icon large>mdi-timeline-outline</v-icon>
        <h4>Cronograma</h4>
        <div></div>
        <cronograma-estudio
          :idEstudio="estudio.idEstudio"
          :cronograma="estudio.cronograma"
        />
      </div>
    </div>

    <v-dialog v-model="cambios.instaladora" max-width="700">
      <InstaladorasEstudios
        @close="cambios.instaladora = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>
    <v-dialog v-model="cambios.comercial" max-width="700">
      <CambiarComercial
        @close="cambios.comercial = false"
        @reload="$emit('reload')"
        :estudio="idEstudio"
      />
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      :value="detailsId"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar>
        <v-toolbar-title>Estudio {{ detailsId }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="detailsId = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <DetallesEstudio :key="detailsId" :_idEstudio="detailsId" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  components: {
    VDateField: () => import("@/components/VDateField.vue"),
    PagosInstalacion: () => import("./PagosInstalacion.vue"),
    DocumentacionEstudio: () =>
      import("@/modules/estudios/components/documentacion/index"),
    InstaladorasEstudios: () => import("../InstaladorasEstudios.vue"),
    CambiarComercial: () => import("../CambiarComercial.vue"),
    GestionTarjeta: () => import("../gestion/GestionTarjeta.vue"),
    DetallesEstudio: () => import("../../views/DetallesEstudio.vue"),
    CalendarioEstudio: () => import("../calendario/CalendarioEstudio.vue"),
    TramitacionesInstalacion: () => import("./TramitacionesInstalacion.vue"),
    CronogramaEstudio: () => import("@/components/CronogramaEstudio.vue"),
  },
  props: {
    idEstudio: Number,
  },
  data() {
    return {
      estudio: {
        estado_actual: {},
        estado_actual_int: {},
        cups_principal: {},
        instalacion: {},
        tramitaciones: [],
      },
      estudio_freeze: {},
      cambios: {
        instaladora: false,
        comercial: false,
      },
      detailsId: null,
      loading: false,
      tiposCliente: [],
      descuentoPrecioVenta: null,
    };
  },
  mounted() {
    if (this.idEstudio) {
      this.getEstudio();
      this.getTiposCliente();
    }
  },
  methods: {
    async getEstudio() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/estudios/${this.idEstudio}`,
      });

      data.instalacion ??= {};
      data.tramitaciones ??= [];

      data.cronograma = data.cronograma
        ? data.cronograma.filter((x) => x.idInstalacion)
        : [];

      this.estudio = data;
      this.estudio_freeze = { ...this.estudio };
    },
    descontarIva(v) {

      if ( this.estudio.instalacion.precioVenta && this.descuentoPrecioVenta ) {
        this.estudio.instalacion.precioVenta /= (( 100 - this.descuentoPrecioVenta ) / 100)
        this.descuentoPrecioVenta = null
        this.$root.$emit('snack', "Porcentaje de descuento eliminado")
      }

      if (typeof v === "object" && !Array.isArray(v) && v !== null) {
        this.estudio.instalacion.precioVenta = v.aplicar_iva
          ? Number(parseFloat(v.precio_instalacion / 1.21).toFixed(2))
          : v.precio_instalacion;
        if (v.aplicar_iva) {
          this.$root.$emit("snack", "Se utiliza el precio sin IVA");
        }
      }
      return;
    },
    async aplicarDescuento() {
      this.estudio.instalacion.precioVenta *= (( 100 - this.descuentoPrecioVenta ) / 100)
      this.estudio.instalacion.precioVenta = Number(parseFloat(this.estudio.instalacion.precioVenta).toFixed(2))
    },
    async updateInstalacion() {
      try {
        this.loading = true;
        const { data } = await axios({
          url: `${process.env.VUE_APP_API_URL}/instalaciones/${this.idEstudio}`,
          method: "POST",
          data: {
            ...this.estudio.instalacion,
          },
        });

        // this.getEstudio();
        this.estudio.instalacion = data;
        this.$root.$emit("snack", "Se han guardado los cambios");
        this.$emit("reload");
        this.getEstudio();
      } catch {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado", "error");
      } finally {
        this.loading = false;
      }
    },
    async getTiposCliente() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/tipo_cliente`,
      });
      this.tiposCliente = data;
    },
  },
};
</script>

<style></style>
